.wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;

  .table {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    row-gap: 10px;
    column-gap: 10px;
    width: 100vmin;
    height: 100vmin;
    max-height: min(calc(100vh - 132px), 500px);
    max-width: min(calc(100vh - 132px), 500px);

    >div {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
      align-items: stretch;
      justify-content: stretch;

      .cell {
        font-weight: 600;
        padding: 0px;

        &.computed {
          background-color: rgba(0, 69, 165, 0.3);
        }
      }
    }


  }

  .manager {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }
}


.picker {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.picker .selector,
.table .cell {
  width: initial;
  height: initial;
  &:global(.ant-btn-icon-only) {
    width: initial;
    height: initial;
  }

  @media (min-width: 600px) {
    font-size: 25px;
  }
}

@just-for-check-color: #282c34;@transitionDuration: 200;