@import './shared.less';

body {
  margin: 0;
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  background-color: @paddings-color;

  h1 {
    text-align: center;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@just-for-check-color: #282c34;@transitionDuration: 200;